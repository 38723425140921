import React, { useState, useEffect } from 'react';
import locationData from '../../components/locations/LocationData';
import { constant } from '@app/utils/constant';
import SubscribeCompoents from "../../components/common/SubscribeCompoents"; 
import { Link } from 'react-router-dom';
import PageTitleComponent from '../../components/PageTitleComponent';
import AOS from 'aos'; 
const Locations = () => {
    const [selectedLocation, setSelectedLocation] = useState('');
    const [locationDataVar, setLocationDataVar] = useState<any>(null);
    const [showLocationInfo, setShowLocationInfo] = useState(false); 
    const handleLocationChange = (e: any) => {
        setSelectedLocation(e.target.value);
    }; 
    const handleFormSubmit = (e: any) => { 
        e.preventDefault();
        if (selectedLocation && locationData[selectedLocation]) {
            setLocationDataVar(locationData[selectedLocation]);
            setShowLocationInfo(true);
        } else {
            setLocationDataVar(null);
            setShowLocationInfo(false);
        }
    }; 
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
        AOS.init({
          disable: "phone",
          duration: 700,
          easing: "ease-out-cubic",
        });
      }, []); 
    return (
        <>
         <PageTitleComponent title={"Locations - Slomins"} />
            <main className="location-page"> 
                <section className="hero_blk">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content text-center">
                            <h1>Areas We Serve</h1>
                            <p>Select a state to discover which services Slomin’s offers in your area.</p>
                            <div className="button_hero-blk">
                                <select id="selectInput" onChange={(event : any) =>handleLocationChange(event)}>
                                    <option value="">Select a State...</option>
                                    <option value="california">California</option>
                                    <option value="connecticut">Connecticut</option>
                                    <option value="delaware">Delaware</option>
                                    <option value="florida">Florida</option>
                                    <option value="georgia">Georgia</option>
                                    <option value="maryland">Maryland</option>
                                    <option value="new-jersey">New Jersey</option>
                                    <option value="new-york">New York</option>
                                    <option value="pennsylvania">Pennsylvania</option>
                                    <option value="virginia">Virginia</option>
                                    <option value="washington-dc">Washington DC</option>
                                </select>
                                <a href="#" className="red_btn" onClick={handleFormSubmit}>Find Locations</a>
                            </div>
                        </div>
                    </div>
                </section> 
                {showLocationInfo && locationDataVar && Array.isArray(locationDataVar) && 
                    locationDataVar.length > 0 && locationDataVar.map((row, podidx) => (
                        <section className="location-details-blk">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-12 text-center">
                                        <h2>{row.title}</h2>
                                        {row.address && (
                                            <p>{row.address}</p>
                                        )}
                                        {row.phone && (
                                            <a href={ `tel:${row.phone}`}><strong>{row.phone}</strong></a>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-12 location-details-inr text-center">
                                        <div className="location-details-heading location_map">
                                            <img className="img-fluid" src={`/img/`+row.image} alt={row.image} style={{maxHeight:'400px'}} />
                                        </div>
                                        <div className="location-available-service">
                                            <p>{row.heading}</p>
                                            {row.html}
                                            <div dangerouslySetInnerHTML={{ __html: row['html-data'] }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    ))
                }
                {showLocationInfo && locationDataVar && !Array.isArray(locationDataVar) && (
                    <section className="location-details-blk">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center">
                                    <h2>{locationDataVar.title}</h2>
                                    {locationDataVar.address && (
                                        <p>{locationDataVar.address}</p>
                                    )}
                                    {locationDataVar.phone && (
                                        <a href={ `tel:${locationDataVar.phone}`}><strong>{locationDataVar.phone}</strong></a>
                                    )}
                                </div>
                                <div className="col-12 col-md-12 location-details-inr text-center">
                                    <div className="location-details-heading location_map">
                                        <img className="img-fluid" src={`/img/`+locationDataVar.image} alt={locationDataVar.image} style={{maxHeight:'400px'}} />
                                    </div>
                                    <div className="location-available-service">
                                        <p>{locationDataVar.heading}</p>
                                        {locationDataVar.html}
                                        <div dangerouslySetInnerHTML={{ __html: locationDataVar['html-data'] }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {/* location-details block section ends here */}
                {/* location Cards block section starts here */}
                <section className="location-page-cards" data-aos="fade-up">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-xl-3">
                                <div className="loc_img_box">
                                    <figure className="loc_img_effect">
                                        <img src="../../../img/advance-security.png" alt="Advanced Security"/>
                                        <figcaption>
                                            <h2>Advanced Security for your home</h2>
                                            <p><Link className="white_btn" to={`/home-security/home-security-cameras`}>Home Security Systems</Link></p>
                                        </figcaption>           
                                    </figure>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-xl-3">
                                <div className="loc_img_box">
                                    <figure className="loc_img_effect">
                                        <img src="../../../img/home-comfort.png" alt="home comfort"/>
                                        <figcaption>
                                            <h2>Maximize your home comfort in the Summer</h2>
                                            <p><a href={`${constant.AC_SITEURL}/for-business/cooling`} className="white_btn">Air Conditioning Services</a></p>
                                        </figcaption>           
                                    </figure>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-xl-3">
                                <div className="loc_img_box">
                                    <figure className="loc_img_effect">
                                        <img src="../../../img/home-warm.png" alt="home warm"/>
                                        <figcaption>
                                            <h2>Keep your home warm during the Winter</h2>
                                            <p><a href={`${constant.OIL_SITEURL}/for-business/heating`} className="white_btn">Oil and Gas Services</a></p>
                                        </figcaption>           
                                    </figure>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-xl-3">
                                <div className="loc_img_box">
                                    <figure className="loc_img_effect">
                                        <img src="../../../img/solution-business.png" alt="solution business"/>
                                        <figcaption>
                                            <h2>Solutions for Businesses</h2>
                                            <p><Link className="white_btn" to={`/for-business`}>Business Services</Link></p>
                                        </figcaption>           
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* location Cards block section ends here */}
                {/* subscribe block section starts here */}
                <SubscribeCompoents/>
                {/* subscribe block section ends here */}
            </main>

        </>
    );
};

export default Locations;
