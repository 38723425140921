import React, { useEffect,useState } from 'react';
import HomeFooter from '@app/modules/main/footer/HomeFooter';
import { constant } from '@app/utils/constant';
import { useSearchParams } from 'react-router-dom'
import Cookies from 'js-cookie';
import RingNotificationBar from '@app/components/common/RingNotificationBar';

const Home = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const pid = (searchParams.size > 0) ? searchParams.get('pid') : "";
    const kw = (searchParams.size > 0) ? searchParams.get('kw') : "";
    const [divHeight, setDivHeight] = useState(0);
    const [removeDivHeight, setRemoveDivHeight] = useState(true)
    if (pid !== '') {
        Cookies.set('pid', pid, { expires: 1 });
    }
    if (kw !== '') {
        Cookies.set('kw', kw, { expires: 1 });
    }

    useEffect(() => {
        document.body.classList.add('body-background-image');
        return () => {
            document.body.classList.remove('body-background-image');
        };
    }, []);

    return (
        <>
            <main>
                <RingNotificationBar setDivHeight={setDivHeight} setRemoveDivHeight={setRemoveDivHeight} />
                <div className="areainterest_content">
                    <div className="container">
                        <div className="areaInterest_box">
                            <div className="areaInterest_box_logo">
                                <a className="header_logo" href="/">
                                    <img className="header_logo_image" src="../img/Slomins-Logo-New.svg" alt="slomins logo" />
                                </a>
                            </div>
                            <p className="textlarge" style={{ textAlign: 'center' }}>Please select area of interest</p>
                            <p className="textimg">
                                <img src="../img/angle-double-down-img-new.png" alt="icon" width={28} height={28} />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="page_services_container">
                    <div className="container">
                        <div className="box_services">
                            <div className="grid_center">
                                <div className="service_box_wrap">
                                    <div className="box_services_inner">
                                        <a href={`${constant.ALARAM_SITEURL}`}>
                                            <span className="box_services_icon">
                                                <img src="../img/shield_img_new.png" alt="Security" />
                                            </span>
                                            <h4 className="bar">Security</h4>
                                        </a>
                                    </div>
                                </div>
                                <div className="service_box_wrap">
                                    <div className="box_services_inner">
                                        <a href={`${constant.OIL_SITEURL}`}>
                                            <span className="box_services_icon">
                                                <img src="../img/Heating_Services_Img.png" alt="Heating" />
                                            </span>
                                            <h4 className="bar">Heating</h4>
                                        </a>
                                    </div>
                                </div>
                                <div className="service_box_wrap">
                                    <div className="box_services_inner">
                                        <a href={`${constant.AC_SITEURL}`}>
                                            <span className="box_services_icon">
                                                <img src="../img/Cooling-Services-Img.png" alt="Cooling" />
                                            </span>
                                            <h4 className="bar">Cooling</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page_returning_content">
                    <div className="container">
                        <div className="returningCust_box">
                            <p className="retcustxt" style={{ textAlign: 'center' }}>Returning customer? <strong>Log in:</strong>
                            </p>
                            <p className="retcusbtn" style={{ textAlign: 'center' }}>
                                <a className="button" href={`${constant.MYA_SITEURL}`} target="_blank" rel="noopener">Manage Your Account »</a>
                            </p>
                        </div>
                    </div>
                </div>
            </main>
            <HomeFooter />
        </>
    );
};

export default Home;
